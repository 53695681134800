var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{staticClass:"message-content-type-condition-value",attrs:{"slim":""}},[_c('select-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
      'func': _vm.newConfigInputSetup,
      'args': {
        'model': _vm.model,
        'key': 'values',
        'multiple': true,
        'options': _vm.messageTypeOptions,
      }
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
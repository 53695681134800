





















import { InputSetups } from '@/mixins/input-setups'
import MessageContentTypeCondition
  from "@/includes/logic/Modules/models/conditions/MessageContentTypeCondition/MessageContentTypeCondition";
import SelectOptionsMixin from "@/mixins/SelectOptionsMixin";

import SelectInput from 'piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue';

import { ValidationObserver } from 'vee-validate'
import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component({
  components: {
    ValidationObserver,
    SelectInput
  }
})
export default class MessageContentTypeConditionView extends Mixins(InputSetups, SelectOptionsMixin) {
  @VModel() model!: MessageContentTypeCondition
}
